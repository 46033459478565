<template>
  <div class="zhft">
    <div class="zhft_banner_wraper">
      <div class="zhft_banner_container">
        <div class="zhft_banner_container_title">智慧饭堂</div>
        <div class="zhft_banner_container_desc">绿色饭堂全流程管理系统</div>
        <div class="zhft_banner_container_detail">
          <span>报餐管理</span><span>取餐管理</span><span>用餐管理</span
          ><span>饭堂管理</span>
        </div>
        <div class="zhft_banner_container_btn">在线咨询</div>
      </div>
    </div>
    <div class="zhft_user">
      <div class="yhtd_user_title">用户痛点</div>
      <div class="yhtd_user_desc">在传统的会议模式下，办会处处伴随着低效……</div>
      <div class="yhtd_user_item">
        <div
          class="yhtd_user_item_li"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="yhtd_user_item_li_img">
            <img :src="item.icon" alt="" />
          </div>
          <div class="yhtd_user_item_li_title">{{ item.title }}</div>
          <div class="yhtd_user_item_li_desc">{{ item.desc }}</div>
          <div class="yhtd_user_item_li_desc">{{ item.info }}</div>
        </div>
      </div>
      <div class="zhft_tab">
        <div class="zhft_tab_title">
          <div
            class="zhft_tab_title_item"
            :class="{ active: activeIndex === 1 }"
            @mouseenter="changeIndex(1)"
          >
            功能架构
          </div>
          <div
            class="zhft_tab_title_item"
            :class="{ active: activeIndex === 2 }"
            @mouseenter="changeIndex(2)"
          >
            应用流程
          </div>
        </div>

        <div class="zhft_tab_change">
          <div class="zhft_tab_change_item" v-if="activeIndex === 1">
            <div class="zhft_tab_change_item_title">
              智能会务，颠覆传统的会议模式
            </div>
            <div class="zhft_tab_change_item_desc">
              这是一款覆盖饭堂管理全流程的饭堂用餐管理系统，能够为政府机关单位提供专业的饭堂用餐管理，并可以与本公司的办公自动化系统进行无缝对接。帮助政府机关单位摒弃传统的饭堂自助用餐模式，打造杜绝浪费、科学配餐、提前订餐的绿色饭堂用餐模式。
            </div>
            <div class="zhft_tab_change_item_img1">
              <img src="@/assets/images/solve/zhft/gnjg.png" alt="" />
            </div>
          </div>
          <div
            class="zhft_tab_change_item zhft_tab_change_item_2"
            v-if="activeIndex === 2"
          >
            <div class="zhft_tab_change_item_img2">
              <img src="@/assets/images/solve/zhft/yylc.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="jjfa_case">
        <div class="jjfa_case_title">解决方案</div>
        <div class="jjfa_case_desc">使用智能会务系统，会议难题迎刃而解</div>
        <div class="jjfa_case_item">
          用餐人员通过APP在线报餐、取餐、点评、查询；随时随地查看每周电子餐谱、<br />并提前报餐选餐，缴费记录一目了然，用餐后点评餐品服务，帮助备餐人员提升餐品质量。
        </div>
        <div class="jjfa_case_content">
          <div class="jjfa_case_content_item">
            <img src="@/assets/images/solve/zhft/jjfa_icon1.png" />
            <p>提前知晓菜谱</p>
          </div>
          <div class="jjfa_case_content_item">
            <img src="@/assets/images/solve/zhft/jjfa_icon2.png" />
            <p>节省排队时间</p>
          </div>
          <div class="jjfa_case_content_item">
            <img src="@/assets/images/solve/zhft/jjfa_icon3.png" />
            <p>无需充值餐卡</p>
          </div>
          <div class="jjfa_case_content_item">
            <img src="@/assets/images/solve/zhft/jjfa_icon4.png" />
            <p>反馈就餐建议</p>
          </div>
        </div>
      </div>
      <div class="pcry_case">
        <div class="pcry_case_title">配餐人员</div>
        <div class="pcry_case_item">
          配餐人员通过系统上传餐谱、查看订餐统计、取餐信息，也可手动查询订单信息为其用餐人员配餐。<br />系统自动统计食材和收费明细。
        </div>
        <div class="pcry_case_content">
          <div class="pcry_case_content_item">
            <img src="@/assets/images/solve/zhft/pcry_icon1.png" />
            <p>实现精准配餐</p>
          </div>
          <div class="pcry_case_content_item">
            <img src="@/assets/images/solve/zhft/pcry_icon2.png" />
            <p>提升餐品服务</p>
          </div>
          <div class="pcry_case_content_item">
            <img src="@/assets/images/solve/zhft/pcry_icon3.png" />
            <p>可控采购库存</p>
          </div>
          <div class="pcry_case_content_item">
            <img src="@/assets/images/solve/zhft/pcry_icon4.png" />
            <p>智能统计分析</p>
          </div>
        </div>
        <div class="pcry_case_image">
          <img id="img1" src="@/assets/images/solve/zhft/pcry_icon5.png" />
          <img id="img2" src="@/assets/images/solve/zhft/pcry_icon6.png" />
        </div>
      </div>
    </div>
    <div class="spyj_hardword">
      <div class="spyj_list">
        <div class="spyj_list_title">适配硬件</div>
        <div class="spyj_list_wraper">
          <div
            class="spyj_list_item"
            v-for="(item, index) in imgList"
            :key="index"
          >
            <img :src="item.img" alt="" class="img" />
            <div class="spyj_list_item_title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="khal_footer">
      <div class="khal_footer_title">客户案例</div>
      <div class="khal_footer_desc">
        佛山市高明区政府智慧饭堂、佛山市南山镇政府智慧饭堂
      </div>
      <div class="khal_footer_item">
        <div class="khal_footer_item_img img1">
          <img src="@/assets/images/solve/zhft/khal_icon1.png" alt="" />
        </div>
        <div class="khal_footer_item_img img2">
          <img src="@/assets/images/solve/zhft/khal_icon2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: '',
  components: {

  },
  props: {

  },
  data () {
    return {
      activeIndex: 1,
      liIndex: 1,
      list: [
        {
          icon: require('@/assets/images/solve/zhft/yhtd_icon1.png'),
          title: '使用餐卡就餐',
          desc: '用餐人员必须每天携带餐卡刷卡就餐，',
          info: '餐卡时常需要手动充值，并容易丢失'
        }, {
          icon: require('@/assets/images/solve/zhft/yhtd_icon2.png'),
          title: '食材浪费',
          desc: '备餐人员无法精准预估',
          info: '每天食材准备的数量，容易造成食材浪费'
        }, {
          icon: require('@/assets/images/solve/zhft/yhtd_icon3.png'),
          title: '缺乏反馈',
          desc: '就餐人员和备餐人员缺乏沟通，',
          info: '无法优化餐品服务'
        },
        {
          icon: require('@/assets/images/solve/zhft/yhtd_icon4.png'),
          title: '月末人工对账',
          desc: '财务每月人工统计饭堂账单，',
          info: '费时费力还容易出错'
        }
      ],
      imgList: [
        {
          img: require('@/assets/images/solve/zhft/spyj_icon1.png'),
          title: '用餐人员取餐设备'
        },
        {
          img: require('@/assets/images/solve/zhft/spyj_icon2.png'),
          title: '食堂工作人员人员查看报餐设备'
        },
        {
          img: require('@/assets/images/solve/zhft/spyj_icon3.png'),
          title: '电子餐卡扫码设备'
        }
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {
    window.addEventListener('scroll', this.slide)
    setTimeout(() => {
      $('.yhtd_user_item_li').addClass('animation')
    }, 100)
  },
  destroyed () {
    window.addEventListener('scroll', this.slide)
  },
  methods: {
    slide () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      const targetHeight = $('.khal_footer_item').offset().top
      const imgHeight = $('.spyj_list_item').offset().top
      const pcryHeight = $('.pcry_case').offset().top
      if (h > targetHeight - 1000) {
        $('.khal_footer_item_img').addClass('slide')
      }
      if (h > pcryHeight - 800) {
        $('#img1').addClass('animation1')
        $('#img2').addClass('animation2')
      }
      if (h > imgHeight - 800) {
        $('.img').addClass('scaleImg')
      }
    },
    randomId (id) {
      return (Math.random() * 1000).toFixed(id)
    },
    changeIndex (id) {
      this.activeIndex = id
    },
    change (id) {
      this.liIndex = id
    }
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
